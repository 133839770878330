import { styled } from '@mui/material';

export const Layout = styled('div')<{ $bgMainOffset: number; }>`
  position: relative;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  padding: 0;
  flex-direction: column;
  align-items: center;
  background-image: url('/background-main.svg');
  background-repeat: no-repeat;
  background-position: center ${({ $bgMainOffset }) => `${$bgMainOffset}px`};
`;
