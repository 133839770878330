import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

import { Footer } from './components/Footer';
import { AppLandingHeader } from './components/AppLandingHeader';
import * as SC from './styles';

export function LandingLayout({ children }: { children: ReactNode }) {
  return (
    <SC.Layout $bgMainOffset={0}>
      <AppLandingHeader />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          maxWidth: '1240px',
          width: '100%',
          margin: '0 auto',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
      <Footer />
    </SC.Layout>
  );
}
