import { ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/material';

export interface IAnimatedLogoProps {
  logos: ReactElement[];
}

export const AnimatedLogo = ({ logos }: IAnimatedLogoProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>();

  useEffect(() => {
    setCurrentIndex(0);
    const timerId = setInterval(() => {
      setCurrentIndex(
        (prevState) => (prevState !== undefined && prevState < logos.length - 1) ? prevState + 1 : 0
      );
    }, 4000);
    return () => clearInterval(timerId);
  }, [logos.length]);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'start',
      width: '180px',
    }}>
      {currentIndex !== undefined ? logos[currentIndex] : null}
    </Box>
  );
};
