import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';

import { Link } from '../../components/primitives/Link';
import { uiConfig } from '../../uiConfig';

export function AppLandingHeader() {
  const headerHeight = 64;

  return (
    <Box
      component="header"
      sx={(theme) => ({
        height: headerHeight,
        width: '100vw',
        position: 'sticky',
        top: 0,
        transition: theme.transitions.create('top'),
        zIndex: theme.zIndex.appBar,
        backgroundColor: theme.palette.background.header,
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        padding: {
          xs: '12px 16px',
          xsm: '12px 24px',
        },
        backdropFilter: 'blur(24px)',
      })}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 auto',
        maxWidth: '1240px',
        width: '100%',
      }}>
        <Box
          component={Link}
          href="/"
          aria-label="Go to homepage"
          sx={{
            lineHeight: 0,
            mr: 3,
            transition: '0.3s ease all',
            '&:hover': { opacity: 0.7 },
          }}
        >
          <img src={uiConfig.appLogo} alt="AAVE" width={96} height={40}/>
        </Box>
        <Button
          component={Link}
          href="/leverage/"
          sx={{
            color: '#074245',
            backgroundColor: '#AFF113',
            '&:hover': {
              backgroundColor: '#cafc53',
            }
          }}
          onClick={() => {
            window.gtag('event', 'landing_launch_app');
          }}
        >
          Launch App
        </Button>
      </Box>
    </Box>
  );
}
