import { LandingLayout } from '../src/layouts/LandingLayout';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ROUTES, Link } from '../src/components/primitives/Link';
import { AnimatedLogo } from '../src/ui-kit/AnimatedLogo';
import { UniswapLogo } from '../src/ui-kit/AnimatedLogo/logos/UniswapLogo';
import { CamelotLogo } from '../src/ui-kit/AnimatedLogo/logos/CamelotLogo';
import { QuickswapLogo } from '../src/ui-kit/AnimatedLogo/logos/QuickswapLogo';

export default function Home() {
  const { breakpoints } = useTheme();
  const md = useMediaQuery(breakpoints.up('md'));
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      px: { xs: 4, lg: 0 }
    }}>
      <Box sx={{
        width: { xs: 'calc(100vw - 48px)', md: '768px' },
        marginTop: { xs: '80px', md: '120px' },
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 4, md: 6 },
      }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '36px', md: '56px' },
            fontWeight: 600,
            lineHeight: { xs: '40px', md: '60px' },
            letterSpacing: '-1.12px',
            textAlign: 'center',
          }}
        >
          {md && (
            <Box
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 5,
                  justifyContent: 'center',
                  mt: 1,
                  mb: 2.5,
                  ['> div']: { mt: 2 }
                }}
              >
                Leverage your
                <AnimatedLogo
                  logos={[
                    <UniswapLogo key="uniswap"/>,
                    <CamelotLogo key="camelot"/>,
                    <QuickswapLogo key="quickswap"/>,
                  ]}
                />
              </Box>
              <Box>position or get a loan for it</Box>
            </Box>
          )}
          {!md && (
            <Box
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <Box>Leverage your</Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '44px',
                  mt: 2,
                  mb: 2,
                  ml: -5,
                  ['> div']: {
                    mb: -2,
                    transform: 'scale(0.75)',
                  }
                }}
              >
                <AnimatedLogo
                  logos={[
                    <UniswapLogo key="uniswap" />,
                    <CamelotLogo key="camelot" />,
                    <QuickswapLogo key="quickswap" />,
                  ]}
                />
                position
              </Box>
              <Box>or get a loan for it</Box>
            </Box>
          )}
        </Typography>
        <Typography
          sx={{
            color: '#ABABBF',
            fontSize: { xs: '18px', md: '24px' },
            fontWeight: 500,
            lineHeight: { xs: '24px', md: '32px' },
            letterSpacing: '0.06px',
            textAlign: 'center',
          }}
        >
          YLDR Protocol allows anyone to boost LP returns, borrow digital assets against LP position or other assets,
          and supply liquidity to earn yield
        </Typography>
      </Box>

      <Box
        sx={{
          display: { xs: 'flex', md: 'grid' },
          flexDirection: { xs: 'column', md: 'unset' },
          gridTemplateColumns: { xs: 'unset', md: 'repeat(3, 1fr)' },
          gap: { xs: 3, md: 4 },
          marginTop: { xs: '72px', md: '96px' },
          width: '100%',
          ['> *']: {
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 4, md: 6 },
            padding: { xs: 8, md: 6 },
            minHeight: { xs: '224px', md: '280px' },
            borderRadius: '12px',
            border: '1px solid rgba(255, 255, 255, 0.06)',
          }
        }}
      >
        <Box
          sx={{
            background: 'radial-gradient(23.57% 25.78% at 16.96% 19.14%, rgba(189, 242, 44, 0.08) 0%, rgba(189, 242, 44, 0.00) 100%), #18181B',
          }}
        >
          <Box sx={{
            position: { xs: 'absolute', md: 'static' },
            ['> img']: {
              width: { xs: '40px', md: '48px' }
            }
          }}>
            <img src="/leverage.svg" alt=""/>
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '32px' },
              marginLeft: { xs: '52px', md: '0' },
              fontWeight: 600,
              lineHeight: '120%',
              letterSpacing: '-0.32px',
            }}
          >
            Leverage
          </Typography>
          <Typography variant="secondary16" sx={{ fontSize: '18px', color: '#D3D3DE' }}>
            Leverage LP returns and increase your exposure
          </Typography>
          <Link
            href={ROUTES.leverage}
            onClick={() => {
              window.gtag('event', 'landing_footer_products', {
                name: 'leverage'
              });
            }}
          >
            <img src="/icon-button.svg" alt=""/>
          </Link>
        </Box>

        <Box
          sx={{
            background: 'radial-gradient(22.84% 23.02% at 17.32% 19.14%, rgba(111, 69, 247, 0.12) 0%, rgba(111, 69, 247, 0.00) 100%), #18181B',
          }}
        >
          <Box sx={{
            position: { xs: 'absolute', md: 'static' },
            ['> img']: {
              width: { xs: '40px', md: '48px' }
            }
          }}>
            <img src="/borrow.svg" alt=""/>
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '32px' },
              marginLeft: { xs: '52px', md: '0' },
              fontWeight: 600,
              lineHeight: '120%',
              letterSpacing: '-0.32px',
            }}
          >
            Borrow
          </Typography>
          <Typography variant="secondary16" sx={{ fontSize: '18px', color: '#D3D3DE' }}>
            Get instant loan for your position or digital assets
          </Typography>
          <Link
            href={ROUTES.lending}
            onClick={() => {
              window.gtag('event', 'landing_footer_products', {
                name: 'borrow'
              });
            }}
          >
            <img src="/icon-button.svg" alt=""/>
          </Link>
        </Box>

        <Box
          sx={{
            background: 'radial-gradient(24.28% 26.06% at 17.68% 19.14%, rgba(49, 252, 221, 0.08) 0%, rgba(49, 252, 221, 0.00) 100%), #18181B'
          }}
        >
          <Box sx={{
            position: { xs: 'absolute', md: 'static' },
            ['> img']: {
              width: { xs: '40px', md: '48px' }
            }
          }}>
            <img src="/supply.svg" alt=""/>
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '32px' },
              marginLeft: { xs: '52px', md: '0' },
              fontWeight: 600,
              lineHeight: '120%',
              letterSpacing: '-0.32px',
            }}
          >
            Supply
          </Typography>
          <Typography variant="secondary16" sx={{ fontSize: '18px', color: '#D3D3DE' }}>
            Supply liquidity to earn delta-neutral returns
          </Typography>
          <Link
            href={ROUTES.lending}
            onClick={() => {
              window.gtag('event', 'landing_footer_products', {
                name: 'supply'
              });
            }}
          >
            <img src="/icon-button.svg" alt=""/>
          </Link>
        </Box>
      </Box>

      <Box
        sx={{
          display: { xs: 'flex', md: 'grid' },
          flexDirection: { xs: 'column-reverse', md: 'unset' },
          gridTemplateColumns: { xs: 'unset', md: 'auto 456px' },
          gap: { xs: 8, md: 20 },
          width: { xs: '100vw', md: '100%' },
          marginTop: { xs: '72px', md: '96px' },
          padding: { xs: '32px 16px', md: '80px 64px' },
          borderRadius: '24px',
          border: '1px solid rgba(255, 255, 255, 0.06)',
          background: 'radial-gradient(40.54% 77.36% at 24.21% 50%, rgba(189, 242, 44, 0.08) 0%, rgba(189, 242, 44, 0.00) 100%), #18181B',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '40px' },
              lineHeight: { xs: '36px', md: '46px' },
              fontWeight: '600',
            }}
          >
            Leverage your LP positions
          </Typography>
          <Typography
            variant="secondary21"
            sx={{
              fontSize: { xs: '18px', md: '20px' },
              color: '#D3D3DE',
            }}
          >
            Earn up to 5x in fees. Boost LP returns without losing ownership of your initial position.
          </Typography>
        </Box>
        <Box sx={{
          ['> img']: {
            width: { xs: '100%', md: 'auto' }
          }
        }}>
          <img src="/leverage-position.svg" alt=""/>
        </Box>
      </Box>

      <Box
        sx={{
          display: { xs: 'flex', md: 'grid' },
          flexDirection: { xs: 'column', md: 'unset' },
          gridTemplateColumns: { xs: 'unset', md: '544px auto' },
          gap: { xs: 8, md: 20 },
          width: { xs: '100vw', md: '100%' },
          marginTop: { xs: '16px', md: '24px' },
          padding: { xs: '32px 16px', md: '80px 64px' },
          borderRadius: '24px',
          border: '1px solid rgba(255, 255, 255, 0.06)',
          background: 'radial-gradient(40.54% 77.36% at 24.21% 50%, rgba(189, 242, 44, 0.08) 0%, rgba(189, 242, 44, 0.00) 100%), #18181B',
        }}
      >
        <Box sx={{
          ['> img']: {
            width: { xs: '100%', md: 'auto' }
          }
        }}>
          <img src="/borrow-for-uniswap.svg" alt=""/>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '40px' },
              lineHeight: { xs: '36px', md: '46px' },
            }}
          >
            Borrow for LP position <br/>or digital assets
          </Typography>
          <Typography
            variant="secondary21"
            sx={{
              fontSize: { xs: '18px', md: '20px' },
              color: '#D3D3DE',
            }}
          >
            Put your position at work. Use your positions or digital assets as a collateral to borrow more assets, while
            still earning fees as a liquidity provider.
          </Typography>
        </Box>
      </Box>

      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: '32px', md: '48px' },
          lineHeight: { xs: '36px', md: '52px' },
          marginTop: { xs: '72px', md: '96px' },
          fontWeight: '600',
          color: '3F1F1F3',
        }}
      >
        How it works
      </Typography>

      <Box
        sx={{
          display: { xs: 'flex', md: 'grid' },
          flexDirection: { xs: 'column-reverse', md: 'unset' },
          gridTemplateColumns: { xs: 'unset', md: 'auto 544px' },
          gap: { xs: 8, md: 20 },
          marginTop: { xs: '24px', md: '40px' },
          padding: { xs: '32px 16px', md: '64px' },
          width: '100%',
          borderRadius: '24px',
          border: '1px solid rgba(255, 255, 255, 0.06)',
          backgroundColor: '#18181B',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            ['> div']: {
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
              padding: { xs: '16px 20px', md: '20px 24px' },
              marginBottom: '8px',
              border: '1px solid rgba(255, 255, 255, 0.12)',
              borderRadius: '16px',
              ':hover': {
                backgroundColor: '#1F1F23',
                color: '#D3D3DE',
              }
            },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: '600',
              fontSize: { xs: '28px', md: '40px' },
              lineHeight: { xs: '36px', md: '46px' },
              marginBottom: { xs: '16px', md: '24px' },
            }}
          >
            Leverage
          </Typography>
          <Box>
            <Typography
              variant="h3"
              sx={{
                color: '#F1F1F3',
                fontSize: { xs: '18px', md: '24px' },
                fontWeight: 600,
                margin: 0,
              }}
            >
              Leverage LP position
            </Typography>
            <Typography
              variant="secondary16"
              sx={{
                color: '#696986',
                fontSize: { xs: '16px', md: '18px' },
              }}
            >
              Use your position as a collateral to borrow more liquidity from the market in a single transaction.
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h3"
              sx={{
                color: '#F1F1F3',
                fontSize: { xs: '18px', md: '24px' },
                fontWeight: 600,
                margin: 0,
              }}
            >
              Boost your fees up to 5x
            </Typography>
            <Typography
              variant="secondary16"
              sx={{
                color: '#696986',
                fontSize: { xs: '16px', md: '18px' },
              }}
            >
              Get instant liquidity and earn up to 5x more trading fees.
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h3"
              sx={{
                color: '#F1F1F3',
                fontSize: { xs: '18px', md: '24px' },
                fontWeight: 600,
                margin: 0,
              }}
            >
              Get your position back
            </Typography>
            <Typography
              variant="secondary16"
              sx={{
                color: '#696986',
                fontSize: { xs: '16px', md: '18px' },
              }}
            >
              Simply repay the debt and get initial position back to wallet.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            margin: 'auto 0',
            ['> img']: {
              width: { xs: '100%', md: 'auto' }
            }
          }}
        >
          <img src="/leverage-scheme.svg" alt=""/>
        </Box>
      </Box>

      <Box
        sx={{
          display: { xs: 'flex', md: 'grid' },
          flexDirection: { xs: 'column', md: 'unset' },
          gridTemplateColumns: { xs: 'unset', md: '544px auto' },
          gap: { xs: 8, md: 20 },
          marginTop: { xs: '16px', md: '24px' },
          padding: { xs: '32px 16px', md: '64px' },
          width: '100%',
          borderRadius: '24px',
          border: '1px solid rgba(255, 255, 255, 0.06)',
          backgroundColor: '#18181B',
        }}
      >
        <Box
          sx={{
            margin: 'auto 0',
            ['> img']: {
              width: { xs: '100%', md: 'auto' }
            }
          }}
        >
          <img src="/trading-fees.svg" alt=""/>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            ['> div']: {
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
              padding: { xs: '16px 20px', md: '20px 24px' },
              marginBottom: '8px',
              border: '1px solid rgba(255, 255, 255, 0.12)',
              borderRadius: '16px',
              ':hover': {
                backgroundColor: '#1F1F23',
                color: '#D3D3DE',
              }
            },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: '600',
              fontSize: { xs: '28px', md: '40px' },
              lineHeight: { xs: '36px', md: '46px' },
              marginBottom: { xs: '16px', md: '24px' },
            }}
          >
            Borrow
          </Typography>
          <Box>
            <Typography
              variant="h3"
              sx={{
                color: '#F1F1F3',
                fontSize: { xs: '18px', md: '24px' },
                fontWeight: 600,
                margin: 0,
              }}
            >
              Put your LP position at work
            </Typography>
            <Typography
              variant="secondary16"
              sx={{
                color: '#696986',
                fontSize: { xs: '16px', md: '18px' },
              }}
            >
              Previously, when user created liquidity provider position he couldn’t use those funds.
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h3"
              sx={{
                color: '#F1F1F3',
                fontSize: { xs: '18px', md: '24px' },
                fontWeight: 600,
                margin: 0,
              }}
            >
              Get instant liquidity
            </Typography>
            <Typography
              variant="secondary16"
              sx={{
                color: '#696986',
                fontSize: { xs: '16px', md: '18px' },
              }}
            >
              Get a loan for your position or any digital asset to use new liquidity in any way you want.
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h3"
              sx={{
                color: '#F1F1F3',
                fontSize: { xs: '18px', md: '24px' },
                fontWeight: 600,
                margin: 0,
              }}
            >
              Get your position or asset back
            </Typography>
            <Typography
              variant="secondary16"
              sx={{
                color: '#696986',
                fontSize: { xs: '16px', md: '18px' },
              }}
            >
              Simply repay the debt and get your initial position or digital asset back to your wallet.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: '32px', md: '48px' },
          lineHeight: { xs: '36px', md: '52px' },
          marginTop: { xs: '72px', md: '96px' },
          fontWeight: '600',
          color: '3F1F1F3',
        }}
      >
        Safety
      </Typography>

      <Box
        sx={{
          display: { xs: 'flex', md: 'grid' },
          flexDirection: { xs: 'column', md: 'unset' },
          gridTemplateColumns: { xs: 'unset', md: 'repeat(3, 1fr)' },
          gap: { xs: 3, md: 4 },
          marginTop: { xs: '16px', md: '24px' },
          marginBottom: { xs: '80px', md: '144px' },
          width: '100%',
          ['> *']: {
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 4, md: 6 },
            padding: { xs: 8, md: 6 },
            minHeight: { xs: '198px', md: '256px' },
            borderRadius: '12px',
            border: '1px solid rgba(255, 255, 255, 0.06)',
          }
        }}
      >
        <Box
          sx={{
            background: 'radial-gradient(22.84% 23.02% at 17.32% 19.14%, rgba(111, 69, 247, 0.12) 0%, rgba(111, 69, 247, 0.00) 100%), #18181B',
          }}
        >
          <Box sx={{
            position: { xs: 'absolute', md: 'static' },
            ['> img']: {
              width: { xs: '40px', md: '48px' }
            }
          }}>
            <img src="/assets-in-control.svg" alt=""/>
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '32px' },
              marginLeft: { xs: '52px', md: '0' },
              fontWeight: 600,
              lineHeight: '120%',
              letterSpacing: '-0.32px',
            }}
          >
            Secured
          </Typography>
          <Typography variant="secondary16" sx={{ fontSize: '18px', color: '#D3D3DE' }}>
            {'YLDR\'s smart contracts are audited by CertiK, a renowned blockchain security company.'}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<img src="/certik-icon.svg" alt=""/>}
            href="https://yldr.gitbook.io/yldr/audits/audits"
            target="_blank"
            sx={{
              width: 'max-content',
            }}
          >
            Audited by CertiK
          </Button>
        </Box>

        <Box
          sx={{
            background: 'radial-gradient(23.57% 25.78% at 16.96% 19.14%, rgba(189, 242, 44, 0.08) 0%, rgba(189, 242, 44, 0.00) 100%), #18181B',
          }}
        >
          <Box sx={{
            position: { xs: 'absolute', md: 'static' },
            ['> img']: {
              width: { xs: '40px', md: '48px' }
            }
          }}>
            <img src="/decentralized.svg" alt=""/>
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '32px' },
              marginLeft: { xs: '52px', md: '0' },
              fontWeight: 600,
              lineHeight: '120%',
              letterSpacing: '-0.32px',
            }}
          >
            Decentralized
          </Typography>
          <Typography variant="secondary16" sx={{ fontSize: '18px', color: '#D3D3DE' }}>
            YLDR protocol is decentralized, and operations are performed by smart contracts.
          </Typography>
        </Box>

        <Box
          sx={{
            background: 'radial-gradient(24.28% 26.06% at 17.68% 19.14%, rgba(49, 252, 221, 0.08) 0%, rgba(49, 252, 221, 0.00) 100%), #18181B',
          }}
        >
          <Box sx={{
            position: { xs: 'absolute', md: 'static' },
            ['> img']: {
              width: { xs: '40px', md: '48px' }
            }
          }}>
            <img src="/transparent.svg" alt=""/>
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '28px', md: '32px' },
              marginLeft: { xs: '52px', md: '0' },
              fontWeight: 600,
              lineHeight: '120%',
              letterSpacing: '-0.32px',
            }}
          >
            Non-custodial
          </Typography>
          <Typography variant="secondary16" sx={{ fontSize: '18px', color: '#D3D3DE' }}>
            YLDR protocol do not maintain control over user’s assets, they can be withdrawn at any time.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <LandingLayout>{page}</LandingLayout>;
};
