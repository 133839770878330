import { styled } from 'styled-components';
import { Box } from '@mui/material';

export const LogoContainer = styled(Box)<{ $width?: string }>`
  display: inline-flex;
  border-radius: 26px;
  height: 52px;
  padding: 1px;
  animation: increaseWidth 0.5s ease-in-out forwards;

  @keyframes increaseWidth {
    from {
      width: 52px;
    }
    to {
      width: ${({ $width }) => $width || '210px'};
    }
  }
`;

export const LogoWrapper = styled(Box)`
  display: flex;
  justify-content: end;
  height: 100%;
  border-radius: 26px;
  overflow: hidden;
`;

export const LogoInner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 26px;
  height: 100%;
  padding: 4px 16px 4px 12px;
  animation: animatePadding 0.5s ease 0.5s forwards;

  @keyframes animatePadding {
    from {
      padding-left: 12px;
    }
    to {
      padding-left: 4px;
    }
  }
`;

