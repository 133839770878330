import CamelotLogoIcon from '/public/camelot-logo.svg';

import * as SC from '../styles';

export const CamelotLogo = () => (
  <SC.LogoContainer sx={{
    boxShadow: '0px 1px 32px 0px rgba(255, 175, 29, 0.12), 0px 1.083px 26px 0px #0C0C0D',
    background: 'linear-gradient(90deg, rgba(229, 166, 36, 0.7) 0%, rgba(208, 138, 35, 0.3) 100%)',
  }}>
    <SC.LogoWrapper>
      <SC.LogoInner sx={{
        background: 'linear-gradient(to right, rgba(255, 175, 29, 0.12), rgba(255, 175, 29, 0.00)), #18181B',
      }}>
        <CamelotLogoIcon />
      </SC.LogoInner>
    </SC.LogoWrapper>
  </SC.LogoContainer>
);
