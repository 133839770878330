import QuickswapLogoIcon from '/public/quickswap-logo.svg';

import * as SC from '../styles';

export const QuickswapLogo = () => (
  <SC.LogoContainer
    sx={{
      boxShadow: '0px 1px 32px 0px rgba(68, 138, 255, 0.12), 0px 1.083px 26px 0px #0C0C0D',
      background: 'linear-gradient(90deg, rgba(68, 138, 255, 0.7) 0%, rgba(68, 138, 255, 0.3) 100%)',
    }}
    $width="248px"
  >
    <SC.LogoWrapper>
      <SC.LogoInner sx={{
        background: 'radial-gradient(543.78% 109.21% at 0% 50%, rgba(68, 138, 255, 0.12) 0%, rgba(68, 138, 255, 0.00) 100%), #18181B',
      }}>
        <QuickswapLogoIcon />
      </SC.LogoInner>
    </SC.LogoWrapper>
  </SC.LogoContainer>
);
