import UniswapLogoIcon from '/public/uniswap-logo.svg';

import * as SC from '../styles';

export const UniswapLogo = () => (
  <SC.LogoContainer sx={{
    boxShadow: '0px 1px 32px 0px rgba(255, 0, 122, 0.20), 0px 1.083px 26px 0px #0C0C0D',
    background: 'linear-gradient(90deg, rgba(228, 22, 117, 0.7) 0%, rgba(193, 24, 97, 0.3) 100%)',
  }}>
    <SC.LogoWrapper>
      <SC.LogoInner sx={{
        background: 'linear-gradient(to right, rgba(255, 0, 122, 0.16), rgba(255, 0, 122, 0.00)), #18181B',
      }}>
        <UniswapLogoIcon />
      </SC.LogoInner>
    </SC.LogoWrapper>
  </SC.LogoContainer>
);
