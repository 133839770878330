import { Box, Typography } from '@mui/material';
import { Link as MuiLink, LinkProps, ROUTES } from '../../components/primitives/Link';
import { FC } from 'react';

const Link: FC<LinkProps> = (props) => (
  <MuiLink
    {...props}
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      color: '#ABABBF',
      fontSize: '16px',
      [':hover']: {
        color: '#c7c7d4',
      }
    }}
  />
)

export const Footer = () => (
  <Box sx={{
    width: '100vw',
    padding: '32px 16px',
    borderTop: '1px solid rgba(255, 255, 255, 0.16)',
    background: 'linear-gradient(180deg, rgba(79, 79, 99, 0.08) 0%, rgba(79, 79, 99, 0.00) 100%)',
    backdropFilter: 'blur(24px)',
  }}>
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
      gridTemplateRows: { xs: 'repeat(2, auto)', md: 'unset' },
      gridTemplateAreas: { xs: '"a b" "c d"', md: 'unset' },
      columnGap: { xs: 8, md: 12 },
      rowGap: { xs: 8, md: 'unset' },
      margin: '0 auto',
      maxWidth: '1240px',
      width: '100%',
    }}>

      <Box sx={{
        gridArea: { xs: 'a', md: 'unset' },
        ['> img']: {
          width: { xs: '140px', md: '160px' },
          marginBottom: 1,
        }
      }}>
        <img src="/yldr-logo.svg" alt="" />
        <Typography
          variant="secondary14"
          sx={{
            color: '#ABABBF',
            fontSize: { xs: '13px', md: '14px' }
          }}
        >
          Copyright © {new Date().getFullYear().toString()} YLDR
        </Typography>
      </Box>

      <Box sx={{
        gridArea: { xs: 'c', md: 'unset' },
      }}>
        <Typography
          variant="main16"
          sx={{ fontSize: '18px', marginBottom: 4 }}
        >
          Products
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}>
          <Link
            href={ROUTES.leverage}
            onClick={() => {
              window.gtag('event', 'landing_footer_products', {
                name: 'leverage'
              });
            }}
          >
            Leverage
          </Link>
          <Link
            href={ROUTES.lending}
            onClick={() => {
              window.gtag('event', 'landing_footer_products', {
                name: 'borrow'
              });
            }}
          >
            Borrow
          </Link>
          <Link
            href={ROUTES.lending}
            onClick={() => {
              window.gtag('event', 'landing_footer_products', {
                name: 'lend'
              });
            }}
          >
            Lend
          </Link>
        </Box>
      </Box>

      <Box sx={{
        gridArea: { xs: 'b', md: 'unset' },
        marginTop: { xs: '16px', md: '0' },
      }}>
        <Typography
          variant="main16"
          sx={{ fontSize: '18px', marginBottom: 4 }}
        >
          About
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}>
          <Link
            href="https://yldr.gitbook.io/yldr/"
            target="_blank"
            onClick={() => {
              window.gtag('event', 'landing_footer_about', {
                name: 'docs'
              });
            }}
          >
            Docs
          </Link>
          <Link
            href="/terms-of-service"
          >
            Terms of Service
          </Link>
          <Link
            href="/privacy-policy"
          >
            Privacy Policy
          </Link>
        </Box>
      </Box>

      <Box sx={{
        gridArea: { xs: 'd', md: 'unset' },
      }}>
        <Typography
          variant="main16"
          sx={{ fontSize: '18px', marginBottom: 4 }}
        >
          Socials
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}>
          <Link
            href="https://twitter.com/yldrcom"
            target="_blank"
            onClick={() => {
              window.gtag('event', 'landing_footer_socials', {
                name: 'Twitter'
              });
            }}
          >
            <img src="/twitter.svg" alt="" /> Twitter
          </Link>
          <Link
            href="https://t.me/yldrcom"
            target="_blank"
            onClick={() => {
              window.gtag('event', 'landing_footer_socials', {
                name: 'Telegram'
              });
            }}
          >
            <img src="/telegram.svg" alt="" /> Telegram
          </Link>
          <Link
            href="https://discord.gg/FgVKU48QFk"
            target="_blank"
            onClick={() => {
              window.gtag('event', 'landing_footer_socials', {
                name: 'Discord'
              });
            }}
          >
            <img src="/discord.svg" alt="" /> Discord
          </Link>
        </Box>
      </Box>
    </Box>
  </Box>
);
